import { gql, useQuery } from '@apollo/react-hooks';
import './sass/TopNavigation.scss';

export default function TopNavigation() {
    const { data, error, loading } = useQuery(ME_QUERY); 
    
    if (loading) return <p>Loading...</p>;

    return (
        <div className="components-navigation-top-navigation">
            <div className="buttons">
            </div>
            <div className="user">
                <div className="text">
                    {
                        data ? (
                            <>
                                <p className="name">{`${data.me.name} ${data.me.lastname}`}</p>
                                <p className="email">{data.me.email}</p>
                            </>
                        ) : (
                            <p>{error?.message}</p>
                        )
                    }
                </div>
                <div className="img">
                    <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt="usr" />
                </div>
            </div>
        </div>
    );
}

const ME_QUERY = gql`
    query Me {
        me {
            email
            lastname
            name
        }
    }
`;