import EventCategoriesGrid from "../../components/layouts/EventCategoriesGrid";
import TopNavigation from "../../components/navigation/TopNavigation";
import './sass/Home.scss';

export default function Home() {
    return (
        <div className="app-home">
            <div className="categories">
                <TopNavigation />
                <p className="categories-title">
                    Choose your interactive experience!
                </p>
                <EventCategoriesGrid />
            </div>
        </div>
    );
}