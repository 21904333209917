import CategoryCard from "../cards/CategoryCard";
import './sass/EventCategoriesGrid.scss';

const concertImage = require('../../assets/img/concert.jpg').default;
const standUpImage = require('../../assets/img/standup.jpg').default;
const conferenceImage = require('../../assets/img/conference.jpg').default;
const pollsImage = require('../../assets/img/polls.jpg').default;

export default function EventCategoriesGrid() {
    return (
        <div className="components-layout-event-categories-grid">
            <CategoryCard name="Polls" route="/polls" image={pollsImage} />
            <CategoryCard name="Conferences" route="/conferences" image={conferenceImage} className="conferences" />
            <CategoryCard name="Stand Up Comedy" route="/stream" image={standUpImage} />
            <CategoryCard name="Concerts" route="/stream" image={concertImage} className="concerts"/>
            <CategoryCard name="Private Events" route="/stream" image={concertImage} className="private-events"/>
        </div>
    );
}