import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import useAuth from './hooks/useAuth';
import { AuthProvider } from './contexts/AuthContext';
import AuthScreen from './routes/auth/AuthScreen'
import ParlansApp from './routes/app/ParlansApp';
import { LoadingScreen } from './components/ui/LoadingScreen';

export default function App() {
  	const history = useHistory();
	const [ authState, authDispatchers ] = useAuth();

  	if (authState.isLoading) return <LoadingScreen />;

	return (
		<AuthProvider value={authDispatchers} >
			<Switch>
				{
					authState.isAuth ? (
						<>
							<Route path="/" component={ParlansApp} />
						</>
					) : (
						<>
							<Route exact path="/" component={AuthScreen} />
							{/* Redirección a login. */}
							<Redirect 
								path="*"
								to={{
									pathname: '/',
									state: {
										from: history.location.pathname
									}
								}}
							/>
						</>
					)
				}
			</Switch>
		</AuthProvider >
	);
}
