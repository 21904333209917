import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { createHttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link'
import { ApplicationConfig } from '../application.config';
import Auth from '@aws-amplify/auth';

const httpLink = createHttpLink({
    uri: ApplicationConfig.awsConfig.Api.graphqlEndpoint
});

const url = ApplicationConfig.awsConfig.Api.graphqlEndpoint;
const region = ApplicationConfig.awsConfig.Api.region;
const auth = {
    type: ApplicationConfig.awsConfig.Api.authType,
    jwtToken: async () => {
        const credentials = await Auth.currentSession();
        return credentials.getIdToken().getJwtToken();
    }
}

export default ApolloLink.from([
    createAuthLink({ url, region, auth } as any),
    createSubscriptionHandshakeLink({ url, region, auth } as any, httpLink as any)
] as any)