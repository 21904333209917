import { Route, Switch } from "react-router-dom";
import LeftMenu from "../../components/ui/LeftMenu";
import Home from "./Home";
import Stream from "./loadable-screens/StreamLoader";
import { Polls } from "./Polls";
import { Conference } from "./Conference";
import { ConferenceMode } from "./ConferenceMode";
import './sass/ParlansApp.scss';

const conferenceURL = 'https://4c62a87c1810.us-west-2.playback.live-video.net/api/video/v1/us-west-2.049054135175.channel.TgUC9BcpWMIK.m3u8';
const streams = [
    'https://4c62a87c1810.us-west-2.playback.live-video.net/api/video/v1/us-west-2.049054135175.channel.TgUC9BcpWMIK.m3u8',
    'https://4c62a87c1810.us-west-2.playback.live-video.net/api/video/v1/us-west-2.049054135175.channel.NUiimXpVUGyr.m3u8',
    'https://4c62a87c1810.us-west-2.playback.live-video.net/api/video/v1/us-west-2.049054135175.channel.vz7GFGP6M3xJ.m3u8',
    'https://4c62a87c1810.us-west-2.playback.live-video.net/api/video/v1/us-west-2.049054135175.channel.7hL7yiiFH0Q1.m3u8'
];

export default function ParlansApp() {
    return (
        <div className="parlans-app">
            <LeftMenu />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/events" component={Home} />
                <Route exact path="/polls" component={Polls} />
                <Route 
                    exact path="/stream" 
                    render={() => <Stream urls={streams} />}
                />
                <Route exact path="/conferences" component={ConferenceMode} />
                <Route exact path="/conferences/meeting/:idMeeting" component={Conference} />
                <Route
                    exact
                    path="/conferences/stream/:idMeeting"
                    render={() => <Stream urls={[conferenceURL]} />}
                />
                <Route exact path="/producer" component={Home} />
            </Switch>
        </div>
    );
}