import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import './sass/LeftMenu.scss';

export default function LeftMenu() {
    // TODO: Tipar el context.
    const history = useHistory();
    const authContext: any = useContext(AuthContext);
    
    const signOut = async (evt: React.MouseEvent<HTMLAnchorElement>) => {
        evt.preventDefault();
        await authContext.signOut();
        history.replace('/');
    }

    return (
        <div className="components-ui-left-menu">
            <Link to="/"><FontAwesomeIcon icon="home" /></Link>
            <Link to="/producer"><FontAwesomeIcon icon="video" /></Link>
            {/* Este nadamas es de prueba */}
            <Link to="/stream"><FontAwesomeIcon icon="play" /></Link>
            <a href="/logout" onClick={signOut}><FontAwesomeIcon icon="sign-out-alt" /></a>
        </div>
    );
}