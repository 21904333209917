import './sass/EmptyMeetingScreen.scss';

const parlansLogo = require('../../assets/img/parlans-logo.svg');

interface EmptyMeetingScreenProps {
    status: 'INACTIVE' | 'LOADING' | 'JOINED' | 'HANG_UP';
    retryHandler: () => void;
}

export function EmptyMeetingScreen(props: EmptyMeetingScreenProps) {
    return (
        <div className="components-meetings-empty-meeting-screen">
            <img src={parlansLogo.default} alt="" />
            {
                props.status === 'INACTIVE' ? (
                    <>
                        <p className="inactive">
                            Meeting was inactive and restarting request has been sending
                        </p>
                        <button onClick={props.retryHandler}>Retry join</button>
                    </>
                ) : props.status === 'LOADING' ? (
                    <p className="loading">Loading...</p>
                ) : props.status === 'JOINED' ? (
                    <p className="joined">Joined</p>
                ) : (
                    <>
                        <p className="left">
                            You left the meeting
                        </p>
                        <button onClick={props.retryHandler}>Join again</button>
                    </>
                )
            }
        </div>
    );
}