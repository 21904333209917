import './sass/LoadingScreen.scss';
const parlansLogo = require('../../assets/img/parlans-logo.svg')

export function LoadingScreen() {
    return (
        <div className="component-ui-loadingscreen">
            <div>
                <img src={parlansLogo.default} alt="" />
                <p>Cargando...</p>
            </div>
        </div>
    );
}