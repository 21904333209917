import { useHistory } from 'react-router';
import ImageCardBase from './ImageCardBase';
import './sass/CategoryCard.scss';

interface CategoryCardProps {
    className?: string;
    name: string;
    route: string;
    image: string;
}

export default function CategoryCard(props: CategoryCardProps) {
    const history = useHistory();

    const todoChangeThisRedirect = () => {
        history.push(props.route);
    }
    
    return (
        <ImageCardBase
            className={"components-card-category-card " + (props.className || '')}
            image={props.image}
            onClick={todoChangeThisRedirect}
        >
            <p className="name">{props.name}</p>
            <p className="see-more">See more</p>
        </ImageCardBase>
    );
}