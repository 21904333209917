import './sass/ImageCardBase.scss';

export default function ImageCardBase(props: ImageCardBaseProps) {
    return (
        <div
            className={"components-cards-image-base " + (props.className || '')} 
            style={{
                backgroundImage: `url('${props.image}')`
            }}
            onClick={props.onClick}
        >
            <div className="opacity">
                { props.children }
            </div>
        </div>
    );
}
export interface ImageCardBaseProps {
    children: any;
    image: string;
    className?: string;
    onClick?: () => void;
}