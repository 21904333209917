// Código feo para mostrar funcionalidad de polls.
import { gql, useApolloClient, useSubscription } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import './sass/Polls.scss';

export function Polls() {
    const apollo = useApolloClient();
    const [currentPoll, setCurrentPoll] = useState<Poll | null>(null);
    
    const finishPoll = async () => {
        if (!currentPoll) return;
        const result = await apollo.mutate({
            mutation: FINISH_POLL,
            variables: {
                idPoll: currentPoll.idPoll,
                idMeeting: currentPoll.idMeeting,
            }
        });

        console.log('Finish Poll', result);
    }
    
    return (
        <div className="app-polls">
            <div className="manager">
                <div className="elevated">
                    <p className="title">Polls Management</p>
                    <CreatePoll setCurrentPoll={setCurrentPoll} />
                    {
                        currentPoll ? (
                            <div className="poll">
                                <p>Current Poll</p>
                                <p>ID Poll: {currentPoll.idPoll}</p>
                                <p>ID Meeting: {currentPoll.idMeeting}</p>
                                <p>Pool Question: {currentPoll.question.text}</p>
                                <div className="options">
                                    {
                                        currentPoll.question.options.map(
                                            (option, key) => (
                                                <div className="poll-option" key={`option-${key}`}>
                                                    <p>{option.text}</p>
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                                <button className="finish-poll-btn" onClick={finishPoll}>
                                    Finish Poll
                                </button>
                                <PollResults idMeeting={currentPoll.idMeeting} idPoll={currentPoll.idPoll} />
                            </div>
                        ) : null
                    }
                </div>
            </div>
            <PollPreview />
        </div>
    );
}

interface PollsOption {
    text: string;
    votes?: string;
};

interface Poll {
    idAuthor: string;
    idMeeting: string;
    idPoll: string;
    question: {
        text: string;
        options: PollsOption[];
    }
}

function CreatePoll({ setCurrentPoll }: { setCurrentPoll: (poll: Poll) => void }) {
    const apollo = useApolloClient();
    const [options, setOptions] = useState<PollsOption[]>([]);
    const [optionInput, setOptionInput] = useState('');

    const handleOptionChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setOptionInput(evt.target.value);
    }

    const addOption = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        setOptions([
            ...options,
            {
                text: optionInput
            }
        ]);
        setOptionInput('');
    }

    return (
        <Formik
            initialValues={{ idMeeting: '', question: ''}}
            
            onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);

                const result = await apollo.mutate({
                    mutation: CREATE_POLL,
                    variables: {
                        idMeeting: values.idMeeting,
                        question: {
                            text: values.question,
                            options: options
                        }
                    }
                });
                console.log(result);
                setCurrentPoll(result.data?.createPoll as Poll);
                setSubmitting(false);
            }}
        >
            {({ isSubmitting }) => (
                <Form className="create-poll-form">
                    <p>Create Poll</p>
                    {/* Poll information */}
                    <Field type="text" name="idMeeting" placeholder="ID Meeting" />
                    <Field type="text" name="question" placeholder="Poll Question" />
                    {/* Poll options */}
                    <p>Options</p>
                    <div className="poll-options">
                        <div className="options">
                            {
                                options.map(
                                    (option, key) => (
                                        <div className="poll-option" key={`option-${key}`}>
                                            <p>{option.text}</p>
                                        </div>
                                    )
                                )
                            }
                        </div>
                        <div className="add-option">
                            <input type="text" onChange={handleOptionChange} value={optionInput} placeholder="Poll Option" />
                            <button onClick={addOption}>
                                <FontAwesomeIcon icon="plus" />
                            </button>
                        </div>
                    </div>
                    
                    
                    <button type="submit" disabled={isSubmitting}>
                        Create Poll    
                    </button>
                </Form>
            )}
        </Formik>
    );
}

function PollPreview() {
    const apollo = useApolloClient();
    const [currentPoll, setCurrentPoll] = useState<Poll | null>(null); 
    useSubscription(ON_POLL_CREATED, {
        variables: {
            idMeeting: 'test-meeting'
        },
        onSubscriptionData: (subscriptionData) => {
            console.log('Poll Preview', subscriptionData);
            setCurrentPoll(subscriptionData.subscriptionData.data.onCreatedPoll);
        }
    })
    const [alreadyVoted, setAlreadyVoted] = useState(false);

    if (!currentPoll) return <p>Waiting Pool</p>;
    if (alreadyVoted) return <p>Thank you for your vote.</p>;

    const vote = async (option: number) => {
        const result = await apollo.mutate(
            {
                mutation: VOTE_POLL,
                variables: {
                    idPoll: currentPoll.idPoll,
                    idMeeting: currentPoll.idMeeting,
                    option
                }
            }
        );
        console.log('Vote result', result);
        setAlreadyVoted(true);
    }

    return (
        <div className="poll-preview">
            <p>Poll Preview</p>
            <div className="poll">
                <p>Current Poll</p>
                <p>ID Pool: {currentPoll.idPoll}</p>
                <p>ID Meeting: {currentPoll.idMeeting}</p>
                <p>Pool Question: {currentPoll.question.text}</p>
                <div className="options">
                    {
                        currentPoll.question.options.map(
                            (option, key) => (
                                <div
                                    className="poll-option votable"
                                    key={`option-${key}`}
                                    onClick={() => vote(key)}
                                >
                                    <p>{option.text}</p>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </div>
    );
}

function PollResults(props: any) {
    console.log(props);
    const [currentPoll, setCurrentPoll] = useState<Poll | null>(null); 
    useSubscription(ON_FINISH_POLL, {
        variables: {
            idMeeting: props.idMeeting,
            idPoll: props.idPoll
        },
        onSubscriptionData: (subscriptionData) => {
            console.log('Poll Result', subscriptionData);
            setCurrentPoll(subscriptionData.subscriptionData.data.onFinishedPoll);
        }
    })

    if (!currentPoll) return <p>Waiting Pool Finish</p>;

    return (
        <div className="poll-results">
            <p>Poll Results</p>
            <div className="poll">
                <p>Current Poll</p>
                <p>ID Pool: {currentPoll.idPoll}</p>
                <p>ID Meeting: {currentPoll.idMeeting}</p>
                <p>Pool Question: {currentPoll.question.text}</p>
                <div className="options">
                    {
                        currentPoll.question.options.map(
                            (option, key) => (
                                <div
                                    className="poll-option"
                                    key={`option-${key}`}
                                >
                                    <p>{option.text}</p>
                                    <p>{option.votes}</p>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </div>
    );
}

const ON_POLL_CREATED = gql`
    subscription OnPollCreated($idMeeting: ID!) {
        onCreatedPoll(idMeeting: $idMeeting) {
            idAuthor
            idMeeting
            idPoll
            question {
                text
                options {
                    text
                }
            }
        }
    }
`;

const VOTE_POLL = gql`
    mutation VotePoll($idMeeting: ID!, $idPoll: ID!, $option: Int!) {
        votePoll(idMeeting: $idMeeting, idPoll: $idPoll, option: $option) {
            message
        }
    }
`;

const FINISH_POLL = gql`
    mutation FinishPoll($idMeeting: ID!, $idPoll: ID!) {
        finishPoll(idMeeting: $idMeeting, idPoll: $idPoll) {
            idAuthor
            idMeeting
            idPoll
            question {
                text
                options {
                    text
                    votes
                }
            }
        }
    }
`;

const ON_FINISH_POLL = gql`
    subscription OnFinishedPoll($idMeeting: ID!, $idPoll: ID!) {
        onFinishedPoll(idMeeting: $idMeeting, idPoll: $idPoll) {
            idAuthor
            idMeeting
            idPoll
            question {
                options {
                    votes
                    text
                }
                text
            }
        }
    }
`;

const CREATE_POLL = gql`
    mutation CreatePoll($idMeeting: ID!, $question: InQuestion!) {
        createPoll(idMeeting: $idMeeting, question: $question) {
            idAuthor
            idMeeting
            idPoll
            question {
                text
                options {
                    text
                    votes
                }
            }
        }
    }
`;