import Loadable from 'react-loadable';
import { ApplicationConfig } from '../../../application.config';

export interface Player {
    load: (url: string) => void;
    play: () => void;
    delete: () => void;
    attachHTMLVideoElement: (videoEL: HTMLVideoElement) => void;
    getLiveLatency: () => number;
    setVolume: (volume: number) => void;
}

export interface IVSPlayer {
    isPlayerSupported: boolean;
    create: () => Player;
}

const StreamLoader = Loadable.Map<any, any>({
    loader: {
        Stream: () => import('./Stream'),
        IVSPlayer: async () => {
            console.log('loading');
            // TODO: Falta validar el caso en que falla al cargar el ivs.
            const scriptExists = document.getElementById('ivs-lib');
            
            if (!scriptExists || !(window as any).IVSPlayer) {
                await new Promise((resolve) => {
                    const script = document.createElement('script');
                    // Resuelve la promesa cuando cargue.
                    script.onload = () => resolve(true);
                    script.id = 'ivs-lib';
                    script.src = ApplicationConfig.ivsLib;
                    document.body.appendChild(script);
                });
            }

            return true;
        }
    },
    loading: () => <p>Cargando...</p>,
    render(loaded, props) {
        const Stream = loaded.Stream.default;
        return <Stream {...props} />
    }
})

export default StreamLoader;