import { PopOver, PopOverItem, Sound, useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { useEffect, useRef, useState } from "react";
import styled from 'styled-components';

export function VolumeButton() {
    const audioElement = useRef<HTMLAudioElement | null>(null);
    const [volume, setVolume] = useState(1);
    const meetingManager = useMeetingManager();

    useEffect(
        () => {
            if (!audioElement.current) return;
            audioElement.current.volume = 1;
            meetingManager.audioVideo?.bindAudioElement(audioElement.current);
        },
        []
    )

    const changeVolume = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const newVolume = parseFloat(evt.currentTarget.value);
        console.log(`Volume: ${newVolume}`);
        if (audioElement.current) audioElement.current.volume = newVolume;
        setVolume(newVolume);
    }

    return (
        <>
            <PopOver
                renderButton={(isOpen: boolean) => (
                    <StyledContents 
                        style={{ 
                            backgroundColor: `${isOpen ? '#0042bb' : '#FFF'}`,
                            color: `${isOpen ? '#FFF' : '#3f4149'}`
                        }}
                    >
                        <Sound />
                    </StyledContents>
                )}
                a11yLabel="Volume"
            >
                <PopOverItem testId="volume-control">
                    <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.1"
                        value={volume}
                        onChange={changeVolume} 
                    />
                </PopOverItem>
            </PopOver>
            <audio ref={audioElement} style={{ display: 'none' }}/>
        </>
    );
}

const StyledContents = styled.span`
  display: inline-block;
  border-radius: 100%;
  color: white;
  width: 32px;
  height: 32px;
  padding: 2px;
  svg {
    fill: white;
  }
  &:hover {
    background-color: #0042bb!important;
    color: #fff!important;
  }
`;