import { gql, useApolloClient } from "@apollo/react-hooks";
import { ParlansMessageTypes } from "./useChat";

export const useChatSender = (recipient: string) => {
    const client = useApolloClient();

    const sendMessage = async (msg: string, type: ParlansMessageTypes) => {
        try {
            console.log(msg, type)
            const result = await client.mutate({
                mutation: SEND_MESSAGE,
                variables: {
                    recipient: recipient,
                    message: msg,
                    type: type
                }
            });
            console.log('Message sent: ', result.data);
        } catch (err) {
            console.error(err);
        }
    }

    return { sendMessage };
}

const SEND_MESSAGE = gql`
    mutation ($recipient: ID!, $type: MessageTypes!, $message: String!) {
        sendChatMessage(recipient: $recipient, type: $type, message: $message) {
            type
            message
            userName
            messageId
            recipient
            userName
            messageId
        }
    }
`;
