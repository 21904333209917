import axios from "@aws-amplify/storage/node_modules/axios";
import Lottie from "lottie-react";
import { useEffect, useRef, useState } from "react";

export default function LottieElement(props: LottieElementProps) {
	const [ animation, setAnimation ] = useState<any>({ no: true });
	const lottieref = useRef(null);

	useEffect(() => {
		// Obtener el contenido del .json
		(async () => {
			let response = await axios.get(props.path);

			if (response.status === 200) {
				let obj = response.data;

				setAnimation(obj);
			}
		})();
	}, [props.path]);

	function play() {
		if (!props.autoplay) {
			const node = lottieref.current as any;
			node.play();
		}
	}

	function pause() {
		if (!props.autoplay) {
			const node = lottieref.current as any;
			node.pause();
		}
	}

	return (
		<Lottie
			lottieRef={lottieref}
			animationData={animation}
			autoplay={props.autoplay}
			onMouseEnter={() => play()}
			onMouseLeave={() => pause()}
		/>
	);
}

interface LottieElementProps {
	path: string;
	autoplay: boolean;
}