import ReactDOM from "react-dom";
import App from "./App";
import Links from './providers/Links';
import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory'
import { BrowserRouter } from "react-router-dom";
import {ApolloClient} from 'apollo-client';
import { ApplicationConfig } from "./application.config";
import { Auth } from "aws-amplify";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons';
import "./index.css";

// Cognito config.
Auth.configure(ApplicationConfig.awsConfig)

// Apollo config
const client: any = new ApolloClient({
  link: Links,
  cache: new InMemoryCache()
});

// Icons config
library.add(fas);

ReactDOM.render( 
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);